<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ประวัติการซ่อมบำรุง
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="$store.state.authUser.user_permissions.includes('add_maintenance')"
             @click="$router.push({path:'/maintenance/create'})" color="primary">
        <v-icon left>
          mdi-plus
        </v-icon>
        เพิ่มประวัติการซ่อมบำรุง
      </v-btn>
      <v-btn icon @click="showFilter = !showFilter">
        <v-icon>
          mdi-filter
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" class="pl-4 pb-4">
          <v-card
              style="height: 100%; overflow: auto"
              class="pa-2 rounded-lg elevation-2"
              outlined
              tile
          >
            <v-data-table
                :headers="headers"
                :items="maintenances"
                @click:row="gotoMaintenanceDetail"
            >
              <template #item.vehicle="{ item }">
                <span v-if="item.vehicle !== null">
                      {{ item.vehicle.name }} <span class="indigo--text">( ทะเบียน {{
                    item.vehicle.licence || '-'
                  }} )</span>
                </span>

              </template>
              <template #item.maintenance_item="{ item }">
                {{ showItems(item.maintenance_item) }}
              </template>

              <template v-slot:item.total_price="{item}">
                <p class="text-right mb-0">{{ $currency(item.total_price).format() }}</p>
              </template>

              <template v-slot:item.action="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span>เปิดโดย : {{ item.responsible_user.first_name }} {{ item.responsible_user.last_name }}</span>
                </v-tooltip>
              </template>

              <template slot="body.append">
                <tr style="background-color: #eaf8ff">
                  <td>
                    ค่าซ่อมรวม
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right"><b>{{ $currency(sumTotalPrice).format() }}</b></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>

            </v-data-table>
          </v-card>
        </pane>
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard @search="getMaintenance" :search-option="findHeaders" date vehicle :backwards="1"/>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";

export default {
  components: {FilterCard, Splitpanes, Pane},
  name: "Maintenance",
  data() {
    return {
      showFilter: true,
      optionSearch: '',
      headers: [
        {
          text: 'ID',
          value: 'id',
          width: '120'
        },
        {
          text: 'วันที่',
          value: 'date',
          width: '120'
        },
        {
          text: 'รถ',
          value: 'vehicle',
          width: '200'
        },
        {
          text: 'รายการ',
          value: 'maintenance_item'
        },
        {
          text: 'ราคารวม',
          value: 'total_price',
          align: 'right'
        },
        {
          text: 'หมายเหตุ',
          value: 'remark'
        },
        {
          text: '',
          value: 'action',
          width: 60
        }
      ],
      maintenances: [],
      search: '',
      findHeaders: [
        {
          text: 'ชื่ออะไหล่',
          value: 'part_name'
        },
      ],
      sumTotalPrice: 0
    }
  },
  created() {
    // this.getMaintenance()
  },
  methods: {
    getMaintenance(query) {
      axios.get('/api/tracks/maintenance/' + query)
          .then(res => {
            this.sumTotalPrice = 0
            this.maintenances = res.data.data
            this.maintenances.forEach(m => {
              this.sumTotalPrice += parseFloat(m.total_price)
            })
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    showItems(item) {
      let string = ''
      item.forEach((i, index) => {
        string += (`${i.part.name} ${index !== item.length - 1 ? ', ' : ''}`)
      })
      return string
    },
    gotoMaintenanceDetail(row) {
      // this.$router.push({path: `/maintenance/${row.id}`})
      let routeData = this.$router.resolve({path: `/maintenance/${row.id}`});
      window.open(routeData.href, '_blank');
    }
  }
}
</script>

<style>
.splitpanes__splitter {
  background-color: transparent;
  position: relative;
  width: 0;
  min-width: 0;
}

.splitpanes__splitter:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.4s;
  opacity: 0;
  z-index: 1;
}

.splitpanes--vertical > .splitpanes__splitter {
  min-width: 0px;
}

</style>
